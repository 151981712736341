<template>
  <div class="van-main">
    <div class="area-title">
      <p class="title">
        我的发票
      </p>
    </div>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <van-cell-group v-for="item in tableList" :key="item.id" class="van-hairline-bottom">
            <van-cell title="开票方：" :value="item.invoiceSendName" />
            <van-cell title="收票方：" :value="item.invoiceReceiveName" />
            <van-cell title="开票金额（元）：" :value="utils.moneyFormat(item.invoiceMoney)" />
            <van-cell title="开票日期：" :value="item.invoiceDate" />
            <van-cell>
              <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'invoiceDetail', query: { id: item.invoiceId, type: 'invoice' } })">
                查看详情
              </van-button>
            </van-cell>
          </van-cell-group>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-col span="24">
        <van-button color="#1373CC" block type="primary" @click="$router.back()">
          返回
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Col, Row } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data () {
    return {
      searchForm: {
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [
      ],
      loading: false,
      finished: false,
      refreshing: false
    }
  },
  created () {
  },
  methods: {
    // 搜索
    onSearch () {
      this.page = 1
      this.onLoad()
    },
    onLoad () {
      this.searchForm.pageNum = this.page
      this.api.contract.invoice.listEnterpriseInvoice(this.searchForm).then(result => {
        const resData = this._.cloneDeep(result.data.value)
        this.page = resData.pageNum + 1
        if (this.refreshing) {
          this.tableList = []
          this.refreshing = false
        }
        if (resData.pages === 0 && this._.isEmpty(resData.list)) {
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          this.loading = false
          this.tableList = this._.concat(this.tableList, resData.list)
          if (resData.pageNum === resData.pages) {
            this.finished = true
            this.finishedText = '没有更多了'
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.page = 1
      this.onLoad()
    }
  }
}
</script>
<style lang="less">
.bt-left{
  float: right;
  color: #F59A23
}
.lf{
  float: right;
}
</style>
<style scoped>
  .van-main .van-refresh-list{
    margin-top: 0 !important;
  }
</style>
